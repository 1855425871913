<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      icon="mdi-calendar"
      :title="$t('Schedule')"
      fill-height
      :loading="loading"

      :header-action="displayNewItem"
      :header-action-text="$t('AddSchedulePlan')"
      :header-action-condition="items.length > 0 "

      :no-content-condition="items.length === 0"
      no-content-text="NoEnrollmentWavesMessage"
      no-content-icon="mdi-calendar"
      no-content-button-text="AddSchedulePlan"
      :no-content-button-action="displayNewItem"

  >

    <template #default>
      <v-data-table
          :items="items"
          :headers="headers"
          hide-default-header
          hide-default-footer
          disable-pagination
          class="mt-5"
      >

        <template v-slot:header="{ props }">
          <wsTableHeader :props="props" />
        </template>

        <template v-slot:item="{ item  }">
          <v-hover v-slot="{hover}">
            <tr
                @click="$router.push(businessDashLink('courses/' + uuid + '/study_schedule/' + item.uuid ))"
                style="cursor: pointer !important;"
                :style="`background : ${ hover ? wsLIGHTCARD : ''} !important`"
            >

              <td :style="`border-color: ${wsBACKGROUND}`">
                <router-link
                    :to="businessDashLink('courses/' + uuid + '/study_schedule/' + item.uuid )"
                    :style="`color: ${wsACCENT}`"
                    class="noUnderline underlineHover"

                >
                  <div  class="d-flex align-center linkHover">
                    <h5  >{{ item.name }}</h5>
                  </div>
                </router-link>

              </td>
              <td class="text-no-wrap">
                <h5 > {{  PARSE_DATE(item.education_start) }} </h5>
              </td>
              <td class="text-no-wrap">
                <h5 >{{ PARSE_DATE(item.education_end) }}</h5>
              </td>
              <td class="text-no-wrap">
                <h5 >{{ PARSE_DATE(item.enrollment_start) }}</h5>
              </td>
              <td class="text-no-wrap">
                <h5 >{{ PARSE_DATE(item.enrollment_close) }}</h5>
              </td>
              <td>
                <v-btn @click.stop="displayEditItem(item)"
                       class="noCaps" text :color="wsACCENT">
                  <v-icon small class="mr-1" >mdi-pencil</v-icon>
                  <h5>
                    {{ $t('Edit') }}
                  </h5>


                </v-btn>
              </td>

            </tr>
          </v-hover>
        </template>


      </v-data-table>
    </template>
    <!-- Items Table-->

    <template #dialog>
      <!-- Add / Edit Item Dialog Window -->
      <wsDialog v-model="displayDialog"
                :loading="$store.state.LOADING"
                @save="saveChanges"
                :title="$t('AddSchedulePlan')"
                :show-delete="!newEntity"
                @delete="deleteItem"
                :saveText="saveText"
                :key="updateTrigger"
      >

        <ws-text-field
            v-model="entityData.name"
            :placeholder="$t('EnterName')"
            :label="$t('Name')"
        />
        <v-row class="mt-4">
          <v-col cols="6">
            <ws-date-picker v-model="entityData.education_start"
                            :label="$t('EducationStart')"
                            :placeholder="$t('ChooseDate')" />
          </v-col>
          <v-col cols="6">
            <ws-date-picker v-model="entityData.education_end"
                            :label="$t('EducationEnd')"
                            :placeholder="$t('ChooseDate')" />
          </v-col>
        </v-row>
        <v-row class="mb-0">
          <v-col cols="6">

            <ws-date-picker v-model="entityData.enrollment_start"
                            :label="$t('EnrollmentStart')"
                            :placeholder="$t('ChooseDate')" />
          </v-col>
          <v-col cols="6">
            <ws-date-picker v-model="entityData.enrollment_close"
                            :label="$t('EnrollmentClose')"
                            :placeholder="$t('ChooseDate')" />
          </v-col>
        </v-row>


      </wsDialog>
    </template>

  </sub-page>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "courseCalendar",
  props : ['uuid'],
  data() {
    return {
      updateTrigger : 0,
      loading : true,
      items : [],
      entityDataDefault : {
        name : '',
        firstname : '',
        password : ''
      },
      entityData : { },
      displayDialog: false,
      newEntity : true
    }
  },
  computed : {
    ...mapState('courses', [ 'course' , 'selectedLang' ]),
    saveText() {
      return this.newEntity ? this.$t('Add') : this.$t('Save')
    },
    headers(){
      return [
        { text : this.$t('Name')  , value : 'name'                       },
        { text : this.$t('EducationStart') , value : 'education_start'   },
        { text : this.$t('EducationEnd') , value : 'education_end'   },
        { text : this.$t('EnrollmentStart') , value : 'enrollment_start' },
        { text : this.$t('EnrollmentClose') , value : 'enrollment_close' },
        { text : ''               , value : 'action'      , align : 'right', sortable: false, width : 10}
      ]
    } ,
  },
  watch : {
    course() {

      if ( this.items.length > 0 ) {
        this.INIT_PAGE({
          icon : 'mdi-calendar',
          title : this.$t('Schedule'),
          button : this.$t('AddSchedulePlan'),
          action : this.displayNewItem
        })
      }
    },
  },
  methods : {

    ...mapActions('courses',['GET_ENROLLMENT_WAVES','GET_ENROLLMENT_WAVE','ADD_ENROLLMENT_WAVE', 'DELETE_ENROLLMENT_WAVE']),
    ...mapMutations('courses',[ 'INIT_PAGE']),
    

    flushEntity() {
      this.entityData = JSON.parse(JSON.stringify(this.entityDataDefault))
      if (this.entityType === 'institute') {
        this.entityData.institute_id = this.uuid
        this.entityData = JSON.parse(JSON.stringify(this.entityData))

      }
    },
    saveChanges() {

      if ( !this.entityData.name ) {
        return this.notify(this.$t('PleaseEnterName'))
      }
      if ( !this.entityData.education_start ) {
        return this.notify(this.$t('EnterEducationStart'))
      }
      if ( !this.entityData.education_end ) {
        return this.notify(this.$t('EnterEducationEnd'))
      }
      if ( !this.entityData.enrollment_start ) {
        return this.notify(this.$t('EnterEnrollmentStart'))
      }
      if ( !this.entityData.enrollment_close ) {
        return this.notify(this.$t('EnterEnrollmentEnd'))
      }

      this.newEntity ? this.addItem(this.ADD_ENROLLMENT_WAVE(this.entityData))
                     : this.changeItem(this.ADD_ENROLLMENT_WAVE(this.entityData))
      this.displayDialog = false
      this.flushEntity()

    },
    deleteItem() {

      this.removeItem(this.DELETE_ENROLLMENT_WAVE(this.entityData.uuid) )
      this.displayDialog = false
      this.updateTrigger++

    },

    async addItem(item) {
      let result = await item
      if (!result) { return this.notify( this.$t('NetworkError') ) }
      this.INIT_PAGE({
        icon : 'mdi-calendar',
        title : this.$t('Schedule'),
        button : this.$t('AddSchedulePlan'),
        action : this.displayNewItem
      })
      this.items.push(result)
      this.notify( this.$t('NewSchedulePlanCreated') )
    },
    async changeItem(item) {
      let result = await item

      if (!result) { return this.notify( this.$t('NetworkError') ) }

      var index = this.items
          .findIndex(el => el.uuid === result.uuid )
      if (index === -1 ) { return }

      this.items[index] = result
      this.$set(this.items,index,result)
      this.notify( this.$t('ChangesSaved') )
    },
    async removeItem(deleteAction) {

      if ( !await deleteAction ) {
        return this.notify(this.$t('EntityCannotBeDeleted'))
      }

      var index = this.items
          .findIndex(el => el.uuid === this.entityData.uuid )
      if (index === -1 ) { return }
      this.items.splice(index,1)
      this.notify(this.$t('EnrollmentScheduleDeleted'))

    },

    
    displayNewItem(){
      this.flushEntity()
      this.newEntity = true
      this.entityData.course_id = this.uuid
      this.displayDialog = true

    },
    async displayEditItem(item) {
      this.displayDialog = true;
      this.newEntity = false
      this.entityData = await this.GET_ENROLLMENT_WAVE(item.uuid)
      this.entityData.course_id = this.uuid
  //    this.entityData = JSON.parse(JSON.stringify(this.entityData))
    }
  },
  async mounted() {
    this.INIT_PAGE({
      icon : 'mdi-calendar',
      title : this.$t('Schedule'),
    })
    this.flushEntity()
    let result = await this.GET_ENROLLMENT_WAVES(this.uuid);
    if ( !result || result === true ) {
      this.loading = false
      return
    }
    this.loading = false
    this.items = result ;
    if ( this.items.length > 0 ) {
      this.INIT_PAGE({
        icon : 'mdi-calendar',
        title : this.$t('Schedule'),
        button : this.$t('AddSchedulePlan'),
        action : this.displayNewItem
      })
    }
  }
}
</script>

<style scoped>

</style>